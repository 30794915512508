.div-star {
    position: relative;
  }
  
  .inner-star {
    background-color: lightgrey;
    width: 100%;
    height: 100%;
  }


  
body {
    background-color: #e6e1e1;
}

.canvas-page {
    /* z-index: -1000000; */
}

#canvasContainer {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    cursor: crosshair;
    margin: auto;
    overflow: scroll;
    position: relative;
    /* top: 3rem; */
    width: 80%;
    /* z-index: 100; */
}
  
#canvasContainerPreview {
    background-color: white;
    overflow : hidden;
    width: 100%;
    position: relative;
    height: 100%;
}

/* .studio-link button {
    font-size: 20px;
    text-decoration: none;
    color : #CB6CE6;
    background-color: white;
    border : 3px solid #CB6CE6;
    border-radius: 5px;
    padding : 10px;
    box-shadow: 2px 2px 2px black;
    cursor : pointer;
} */

/* .studio-link {
    margin-left: 15px;
} */

/* .studio-link:hover {
    cursor: pointer;
} */

.action-buttons {
    /* border: 1px solid yellow; */
    min-height: 4rem;
    padding : 0.2em 1.5em;
    position: absolute;
    right: 7.5%;
    top: 0.5%;
    display: flex;
    align-items: center;
}

.action-buttons button img {
    max-width: 20px;
}

.action-buttons button {
    border-radius: 5px;
    border : 2px solid lightgrey;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    margin : 0.5rem;
    /* padding : 1rem 2rem; */
    width: 6rem;
    height : 2.5rem;
}

.action-buttons .save-btn {
    color : #FFF;
    background-color: #458FE5;
}

.action-buttons .preview-btn {
    color : #3DCC99;
    background-color: #F0FFFA;
}

.action-buttons .publish-btn {
    color : #FFF;
    background-color: #9766FF;
}

/* Mouse pointers styling */
.pointer {
    cursor: pointer;
}

.dropdown {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.dropdown input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    padding: 4px 8px;
    text-align: left;
}

.dropdown li:hover {
    background-color: #f2f2f2;
}

.dragging {
    cursor: move;
}

.add-section-container {
    display: flex;
    justify-content: center; 
    margin : 4rem auto;
    height : 3rem;
    width: 80%;
}

.add-section-container button {
    border : 1px solid grey;
    border-radius: 5px;
    padding : 10px;
    font-size: 18px;
    cursor:pointer;
}

.add-section-btn {
    width: 100%;
    background-color: lightgreen;
}

.del-section-btn {

    width: 20%;
    background-color: rgb(255, 0, 0);
}


.project-name-container {
    /* border: 1px solid yellow; */
    min-height: 4rem;
    padding : 0.2em 1.5em;
    /* position: absolute;
    right: 45%;
    top: 0.5%; */
    display: flex;
    align-items: center;
    margin-left: 120px;
    min-width: 30px;


    
   
}




  
  .project-icon {
    width: 20px; /* Set the dimensions as per your design */
    height: 20px;
    margin-right: 10px;
    cursor:pointer;
  }
  
  .project-name-input {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600; /* Bold text */
    outline: none;
    padding: 8px 12px; /* Top and bottom padding */
    /*width: 10%; Full-width */
    transition: border-color 0.3s; 
    min-width: 50px;  /* Minimum width */
    max-width: 100%;  /* Maximum width */
    width: auto;
  }
  
  .project-name-input:focus {
     /* Your preferred focus color */
    border: 1px solid darkgrey; /* Entire border changes color when focused */
    box-shadow: none; /* Remove box-shadow when focused */
    border-radius: 4px; 
    background:white;
    min-width: 50px;  /* Minimum width */
    max-width: 100%;  /* Maximum width */
    width: auto;
  }

@media (max-width : 900px) {
    .action-buttons {
        /* border: 1px solid black; */
        right: 25%;
        top: 8.5%;
        z-index: 99;
    }

    .project-name-container {
        
         min-height: 4rem;
        padding : 0.2em 1.5em;
        /* position: absolute; */
        /* display:none; */
        display: flex;
        align-items: center;
        /* right: 10%;
        top: 0.5%; */
    }
    .project-name-input {
        /* display:none; */
        width: 50%;
        
      }
      .project-icon {
        display:none;
      }
}

.resizable-handle {
    background-color: white;
    border: 3px solid #cb6ce5;
    border-radius: 50%;
    height: 10px;
    margin: -2px;
    position: absolute;
    width: 10px;
}

.resizable-handle:hover {
    background-color: #9766FF;
}

.handle-bottom-right {
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;  /* ↖↘ NorthWest-SouthEast */
}

.handle-top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize; 
}

.handle-top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.handle-bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.error-message {
    color: red;
    font-size: 12px; /* or another size that you prefer */
    margin-bottom: 5px; /* Adjust spacing as needed */
}

.input-error {
    border: 1px solid red;
}
.landing-page {
  background-color: #7acdc7;
  overflow: hidden;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 2em;
  padding-bottom: 2em;
}

.game-text {
  font-family: 'Press Start 2P', sans-serif;
  color: #cb6ce6;
  line-height: 4rem;
  font-size: 38px;
  -webkit-text-stroke: 1.5px black;
}

.game-text .box-text {
  color: #01cc00;
  border: 2px dashed #004a80;
  padding: 5px;
}

.gif-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.gif-cta .cta-text {
  padding: 4rem;
  align-items: first baseline;
  z-index: 1;
}

.gif-cta .cta-text .typewriter-text {
  font-size: 2rem;
  font-family: monospace;
  color: #333333;
  text-align: left;
  text-shadow: 2px 0px 0px #333333;
  overflow: hidden;
}

.gif-cta .cta-text .cta {
  margin-top: 5rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 7px 7px 2px #333333;
  transform: skewX(-10deg);
}

.gif-cta .cta .input-div {
  border: 10px solid red;
  display: flex;
  flex-direction: column;
  width: 78%;
  /* border : none; */
  align-items: end;
  justify-content: center;
}

.gif-cta .cta input {
  border-right: none;
  /* border-left : none; */
  outline: none;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 1.1rem;
  width: 100%;
  /* height: 40%; */
  padding-left: 10px;
  text-decoration: wavy;
  text-decoration: dashed;
  cursor: text;
}

.gif-cta .cta input:focus {
  outline: 3px solid #cb6ce6;
}

.gif-cta .cta .btn-div {
  background-color: #cb6ce6;
  border-bottom: 3px solid black;
  border-right: 0px solid black;
  border-top: 0ch solid black;
  border-left: none;
  margin-left: 0.1rem;
  width: 20%;
  font-family: 'Press Start 2P', sans-serif;
  letter-spacing: 2px;
  display: flex;
  justify-content: end;
}

.gif-cta .cta .btn-div.default button {
  background-color: #ff68b4;
  position: relative;
  border: none;
  border-radius: 3px;
  width: 97%;
  height: 92%;
  top: -2px;
  right: -2px;
  font-family: 'Press Start 2P', sans-serif;
  text-shadow: -1px 1px 1px #333333;
  box-shadow: -3px 3px 10px #cb6ce6;
  letter-spacing: 4px;
}

.gif-cta .cta .btn-div.clicked button {
  background-color: #ff68b4;
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
  font-family: 'Press Start 2P', sans-serif;
  text-shadow: -1px 1px 1px #333333;
  letter-spacing: 4px;
}

.confirmation-text {
  margin-top: 7rem;
  margin-left: 1rem;
  border: 2px solid black;
  position: relative;
  /* border-radius: 10px; */
  background-color: #ffffff;
  color: black;
  font-family: 'VT323', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 2rem;
  text-align: left;
  padding: 2rem;
  box-shadow: 5px 5px 3px #000;
}

.confirmation-text .pika-gif {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: -6.5rem;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.confirmation-text .pika-gif img {
  width: 30%;
}

.confirmation-text a {
  color: #179cf0;
}

.invalid-email {
  position: relative;
  margin-top: 2rem;
  margin-left: 1rem;
  border: 2px solid red;
  background-color: white;
  color: black;
  font-family: monospace;
  font-size: 20px;
  line-height: 2rem;
  padding: 0 2rem;
  width: 80%;
}

.invalid-email li::before {
  content: '!';
  width: 2rem;
  height: 2rem;
  border: 2px solid #d74a4a;
  background-color: #d74a4a;
  color: #ffffff;
  font-family: 'VT323', sans-serif;
  padding: 0.3rem 0.8rem;
  font-size: 30px;
  border-radius: 50%;
  margin-right: 2rem;
}

.invalid-email li {
  text-align: left;
  margin-left: -2rem;
  list-style: none;
}

.card-close-btn {
  position: absolute;
  padding: 5px;
  width: 25px;
  top: -12px;
  right: -14px;
  border: 1px solid #d74a4a;
  border-radius: 25px;
  background-color: #d74a4a;
  color: white;
}

@media (max-width: 1200px) {
  .landing-page {
    /* background-color: red; */
  }

  .gif-cta {
    /* background-color: red; */
    /* max-width: 100vw; */
    flex-direction: column;
  }

  .gif-cta .cta-text {
    /* background-color: red; */
    padding: 1rem;
    max-width: 80vw;
  }

  .gif-cta .cta .input-div {
    width: 70%;
  }

  .gif-cta .cta input {
    font-size: 0.6rem;
    overflow: scroll;
  }

  .gif-cta .cta .btn-div {
    width: 30%;
  }

  .gif-cta .cta .btn-div button {
    letter-spacing: 0px;
    font-size: 10px;
  }

  .invalid-email {
    width: 60vw;
  }

  .confirmation-text .pika-gif {
    top: -8.5rem;
  }
  .confirmation-text .pika-gif img {
    width: 70%;
  }
}

.main-dropdown {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 200px;
    
    
    
  }
  
  .main-item {
    padding: 10px;
    cursor: pointer;
    position: relative; 
  }
  
  .has-nested:hover .nested-dropdown {
    display: block;
  }
  
  .nested-dropdown {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 100%;
    top: 0;
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
  }
  
  .nested-item {
    /* padding: 10px; */
    cursor: pointer;
  }
  
.para {
  /* position: absolute; */
  /* border : 1px solid red; */
  /* text-align: left; */
}

.para p {
  /* border : 1px solid red; */
}
.para-input {
  white-space: pre-wrap;
  /* min-height: 1em; */
}

.para .para-input {
  background: transparent;
  box-sizing: border-box;
}

.para-input:hover {
  cursor: text;
}

.toolbar {
  z-index: 1000;
  min-width: 20%;
  margin-top: -1rem;
  padding: 5px;
  position: relative;
  border: 1px solid black;
  background-color: #f5f5f5;
  /* background-color: #dfdfdf; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.toolbar button,
.toolbar select,
.toolbar .toolbar-input input {
  border: none;
  background-color: #f5f5f5;
  margin: 0 5px;
  /* min-width: 3rem; */
}

.toolbar select {
  max-width: 4rem;
}

.toolbar button.active {
  background-color: #888;
  color: #fff;
  border-radius: 5px;
}

.toolbar .toolbar-btn-Italic {
  font-style: italic;
}

.toolbar .toolbar-btn-Underline {
  text-decoration: underline;
}
.toolbar .toolbar-dropdown-Variant {
  min-width: 5rem;
}

.toolbar .toolbar-input input {
  max-width: 3rem;
}

.toolbar .toolbar-input.Color input {
  /* border : 2px solid black; */
  width: 1.5rem;
  border-radius: 10rem;
}

.toolbar .toolbar-input.Fill input {
  /* border : 2px solid black; */
  width: 1.5rem;
  border-radius: 10rem;
}

.icon {
  width: 1.2rem;
  margin: auto;
  margin-top: 0.2rem;
}

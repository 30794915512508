.div-heading {
  /* background-color: red; */
  
}

.heading {
  position: relative;
}

.heading .view {
  /* border: 1px solid red; */
}

.heading .select {
  /* border : 1px solid black; */
}

.heading-input {
  white-space: pre-wrap;
  /* min-height: 1em; */
  /* max-width: 200px; */
}

.heading.focused .heading-input {
  /* background: transparent; */
  /* border: 1px solid lightblue; */
  border: none;
  outline: none;
  box-sizing: border-box;
}

.heading-input:hover {
  cursor: text;
}

.nav-bar {

    /* background-color: pink; */
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding : 0.2em 1.5em;
    background-color: #F5F5F5;
    /* background-color: lightgrey; */

}

.nav-bar .app-logo {
  display: flex;
  align-items: center;
}

.nav-bar .app-logo h2 {
  /* font-family: sans-serif; */
  font-size: 25px;
  letter-spacing: 2px;
  /* padding-left : 5px; */
  /* font-weight: -10px; */
}

.nav-bar .app-logo img {
  max-block-size: 3em;
  padding-right: 0.5em;
  /* border-radius: 100%; */
}

.nav-bar .controlBgColor {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -2em;
  /* max-width: 300px; */
}

.nav-bar .controlBgColor .colorBlock {
  min-height: 25px;
  min-width: 25px;
  margin: 5px;
  /* background-color: yellow; */
}

.nav-bar .controlBgColor .colorPicker {
  border: 2px solid grey;
  border-radius: 5%;
  flex-basis: 100%;
  position: absolute;
  margin-top: 80px;
  margin-left: 4em;
  background-color: white;
  z-index: 1;
  /* display: block; */
  /* visibility: hidden; */
  /* display: block; */
  /* flex-wrap: wrap; */
  /* margin-top : 5em; */
}

.nav-bar .gen-code {
  background-color: #cb6ce6;
  border: 2px solid black;
  margin: 0.5em;
}

.nav-bar .studio-link a {
  /* font-family: monospace, sans-serif; */
  font-size: 20px;
  /* font-weight: -10px; */
  text-decoration: none;
  /* color : black; */
  color: #cb6ce6;
  background-color: white;
  /* background-color: #CB6CE6; */
  border: 3px solid #cb6ce6;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 2px black;
}

.nav-bar .studio-link:hover {
  cursor: pointer;
}


.nav-bar-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  min-width: 25%;

}

.nav-bar-right h3 {
  margin-right: 10px;
}


.nav-bar-right .google-login{
  padding-top:10px;
}

.google-login .supabase-auth-ui_ui-container{
  width: 100%;
  margin: 0 auto;
}

.user-dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.user-dropdown-content {
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  display: none;
  min-width: 160px;
  position: absolute;
  right: 0;
  text-align: left;
  z-index: 100;  
}

.user-dropdown-content a {
  padding: 12px 16px;
  display: block;
  /* border-bottom: 2px solid lightgrey; */

}

.user-dropdown:hover .user-dropdown-content {
  display: block;
}

.user-dropdown .user-dropdown-content a:hover {
  display: block;
  background-color: #f9f9f9; /* Change background color */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add box shadow */
  color: #333; /* Change text color */
  transform: scale(1.05); /* Slightly scale the dropdown */
  transition: all 0.3s ease;
}

.dropdown-arrow {
  margin-left: 10px;
}

@media (max-width : 1200px) {
  .nav-bar .studio-link a {
      /* background-color: red; */
      /* padding: 0px; */
      font-size: 14px;
  }
}

.discover-link {
  
  color: #333; /* Example text color */
  cursor: pointer;
  /*padding: 10px;*/
}

.discover-link:hover {
  text-decoration: underline; /* Style for hover effect */
  transform: scale(1.05);
}



/* .div-polygon.sides-3 {
    background: lightgray;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    overflow: hidden;
  } */
  
  .div-polygon.sides-3 {
    position: relative;
    
  }
  
  .div-polygon.sides-3 .inner-polygon {
    background: lightgray;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  

  
.imageDialog {
  height: 350px;
  width: 500px;

  z-index: 40000;
  position: relative;
  border-radius: 6px;

  border: 1px solid #676767;
  background: #f5f5f5;
  top: 2px;
}

.imageDialog__header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  background: #e6e6e6;
}

.header-image {
  margin-left: 10px;
}

.imageDialog__content-link {
  padding-top: 35px;
  padding-bottom: 35px;
}

.imageDialog__file-upload {
  padding-top: 20px;
}

.image-file-box-input {
  position: absolute;
  width: 100%;
  right: 0px;
  left: 0px;
  opacity: 0;
  height: 193px;
}

.btn-div p {
  display: flex;
  align-items: center;
  text-align: left;
}

.select-btn {
  cursor: text;
  
}

.select-btn p {
  cursor: text;
  justify-content: center;
  
}

/* .button {
  margin-top: 5px; 
} */

.btn-div .button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.button p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* or any other value that suits your needs */
 
}

.edit-btn {
  background-color: white;
  border: none;
  /* position: absolute; */
}




.projects-container{
    display: flex;
    justify-content: center;
    /* align-items:center; */
    /* height: 100vh; */
    margin: 3rem 0;
    /* border: 1px solid red;     */

}

.project-box:hover .delete-icon-box {
  visibility: visible;
}

#yourProjects {

    display: flex;
    flex-wrap: wrap;
    width:100%;
    max-width: 80vw;

}

#yourProjects h2 {
  text-align: left;
}

.project-box {

    background-color: #FFF;
    flex: 0 0 calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 200px;    
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin:10px;
    position: relative;

}

.project-box:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.new-project-icon {
  margin-top: 20px;
  width: 100px;
}

.box-container {

    padding: 2px 16px;
}

@media (max-width : 1200px) {
    h1 {
        font-size: 1.5rem;
    }
    .projects-container #yourProjects {
        /* border : 1px solid blue; */
        justify-content: center;
    }
    .projects-container #yourProjects .project-box {
        /* border: 1px solid green; */
        width: 100%;
        flex: 0 0 calc(80%);
    }
}


.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }


  /* Add this to your Home.css */
  .delete-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 405px; /* updated width */
    height: 288px; /* updated height */
    background-color: #fff;
    padding: 5px 20px;
    border: 1px solid #ccc;
    border-radius: 8px; /* updated corner radius */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* example drop shadow effect */
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    text-align: center;
  }
  
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .delete-icon:hover {
    color: #FF8080; /* Example color change */
    transform: scale(1.1); /* Slightly enlarged */
  }

  .delete-modal button {
    margin-right: 10px;  /* Add margin to create space between buttons */
  }

  /* .modal-icon {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
  } */

  .modal-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }
  .modal-text {
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif; 
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
  }
  
  .confirm-button, .cancel-button {
    margin: 0 10px;
    /* Add any additional styling for buttons here */
  }



  /* General button styles */
.modal-buttons button {
    width: 172px;
    height: 44px;
    border-radius: 4px;
    border:0px;
    text-align: center;
    padding: 10px 60px; /* Vertical and horizontal padding */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Example drop shadow */
    font-family: 'Poppins', sans-serif; /* Make sure to import Poppins font */
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  /* Specific styles for Confirm Delete button */
  .confirm-button {
    background-color: #FF8080; /* Fill */
    color: #000000; /* Selection color */
  }
  
  /* Specific styles for Cancel button */
  .cancel-button {
    background-color: #E6E6E6; /* Fill */
    color: #000000; /* Assuming the text color is black */
  }

  .confirm-button:hover {
    background-color: #E67676; /* Darken the original red a bit */
    transform: scale(1.05); /* Slightly enlarge */
  }
  
  .cancel-button:hover {
    background-color: #D4D4D4; /* Darken the original gray a bit */
    transform: scale(1.05); /* Slightly enlarge */
  }
  

  
  
  .delete-icon-box {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;  /* You can adjust the size */
    height: 30px; /* You can adjust the size */
    background-color: #fff; /* White background */
    border-radius: 4px; /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);  /* Shadow effect */
    border:0px;
    visibility: hidden;
  }


  .url-open-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #9766FF;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 2; /* To ensure it's above other elements */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease; /* Smooth transition */
  }

  .new-project-box:hover .url-open-button {
    opacity: 1;
    visibility: visible;
    font-weight: bold;
  }


  /* .opentab-icon {
    position: relative;
    top: 1px;
    right: 1px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  } */


  .url-open-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px; /* Adjust as needed */
    border: none; /* Remove default button borders */
    cursor: pointer; /* Cursor appears as hand when hovered */
    background-color: #9766FF; /* Background color (already mentioned in the inline style) */
    color: #FFFFFF; /* Adjust text color as needed */
    border-radius: 5px; /* Optional rounded corners */
    text-decoration: none; /* Remove underline if applied globally to button */
    transition: background-color 0.3s; /* Optional smooth background-color change on hover */
  
}

/* .url-open-button:hover {
    background-color: #7f56d5; 
} */

.opentab-icon {
    /* Spacing between the icon and the text */
    margin-right: 5px;
    width: 20px; /* Adjust width as per the size of the icon */
    height: 20px; /* Adjust height as per the size of the icon */
    vertical-align: middle; /* Optional alignment with the text */
    color : #FFF;
    
}

/* 
.publish-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.publish-modal__content {
  background-color: #e6e1e1;
  border: 1px solid #9766FF;
  padding: 20px 50px;
  width: 400px;
  max-height: 500px;
  overflow: auto;
}

.publish-modal__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.publish-modal__form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  margin-bottom: 10px;
}

.publish-modal__input-field {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 2;
  width: 100%;
}

.publish-modal__label {
  flex: 1;
  text-align: left;
}

.publish-modal__input, 
.publish-modal__textarea {
  flex: 2;
  padding: 5px;
  border: none;
  width: 100%;
}

.publish-modal__textarea {
  height: 60px;
  overflow: auto;
  max-height: 120px;
}

.publish-modal__input-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.publish-modal__suggestions {
  margin-top: 10px;
}

.publish-modal__suggestions-label {
  font-size: 0.7rem;
}

.publish-modal__suggestions-items {
  display: flex;
  gap: 10px;
}

.publish-modal__suggestions-item {
  background-color: grey;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  color: white;
}

.publish-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.publish-modal__button {
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  background-color: #e6e1e1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.publish-modal__button:hover {
  background-color: #d5d0d0;
}

.publish-modal__button--submit {
  background-color: #9766FF;
  color: white;
}

.publish-modal__button--submit:hover {
  background-color: #8155e6;
}

.publish-modal__button--cancel {
  background-color: white;
  border: 1px solid #d5d0d0;
  color: #333;
}

.publish-modal__button--cancel:hover {
  background-color: #f7f7f7;
}

.publish-modal__heading {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
}

.publish-modal__error {
  color: red;
  margin-bottom: 5px;
  align-self: flex-start;
  font-size: 0.7rem;
}

.publish-modal__input--error {
  border: 1px solid red;
} */



.publish-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding:20px;
  z-index: 10000; 
}

.publish-modal-content {
  background-color: #e6e1e1;
  border: 1px solid #9766FF;
  padding: 20px;
  width: 400px;
  max-height: 500px;
  overflow: auto;
  padding: 20px 50px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 5px;
}

label {
  flex: 1;
}

.publish-modal__input, .publish-modal__textarea {
  flex: 2;
  padding: 5px;
  border: none;
}

textarea {
  height: 60px;
  overflow: auto;
  max-height: 120px; /* two-row limit */
}

.input-container {
  display: flex;
  flex-direction: column;
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  margin-top: 10px;
}



.suggestions-items {
  display: flex;
  gap: 10px;
}

.suggestions-items > span {
  background-color: grey;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem; /* Making it smaller */
  color: white;
}


.form-row {
  display: flex;
  justify-content: space-between;
  
  align-items: flex-start; 
  margin-bottom: 10px;
}

label {
  flex: 1;
  text-align: left; /* Ensuring labels are left aligned */
}

.input-field, .input-container, .input-field.input[type="text"], textarea {
  flex: 2;
  width: 100%; /* Ensure they stretch from left to right of their containing column */
}

.input-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.suggestions {
  margin-top: 10px;
}

.suggestions-label {
  font-size: 0.7rem;
}

.suggestions-items {
  display: flex;
  gap: 10px;
}

.suggestions-items > span {
  background-color: grey;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.6rem;
  color: white;
}




.publish-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.publish-modal__button {
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  background-color: #e6e1e1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}






.publish-modal__button:hover {
  background-color: #d5d0d0;
}

.publish-modal__button--submit {
  background-color: #9766FF;
  color: white;
}

.publish-modal__button--submit:hover {
  background-color: #8155e6;
}

.publish-modal__button--cancel {
  background-color: white;
  border: 1px solid #d5d0d0;
  color: #333;
}

.publish-modal__button--cancel:hover {
  background-color: #f7f7f7;
}



.form-heading {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px; /* Adding some space below the heading */
}







.url-error {
  color: red;
  margin-bottom: 5px; /* Spacing between the error message and the input */
  align-self: flex-start; /* Aligns the error message with the left side of the parent container */
  font-size:0.7rem;
}

input[type="text"].error-input {
  border: 1px solid red;
}